.popup {
  display: flex;
  flex-direction: column;
  align-items: center;

  & > h3 {
    margin-top: 10px;
  }
}
.btn-spinner {
  display: flex;
  align-items: center;
  gap: 5px;
}
