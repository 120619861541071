$warning: #ff9800;
.btn-warning {
  --bs-btn-color: #fff !important;
}
.btn-warning:active {
  --bs-btn-color: #fff !important;
}
.container-fluid {
  width: 100% !important;
}

.dopm-tab {
  color: #153d77 !important;
  font-weight: 500 !important;
  text-transform: uppercase !important;

  .dopm-active-tab-indicator {
    height: 2px !important;
    width: 60% !important;
    border-radius: 50% !important;
    background-color: #153d77 !important;
    margin: auto !important;
  }
}

.react-grid-item.react-grid-placeholder {
  pointer-events: none;
}

@import 'bootstrap';

.sidebar {
  margin-left: -250px;

  &.toggled {
    margin-left: 0;
  }
}
